import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import {
    determineItemCategoryTags,
    determineOrderCategories,
    estimateStatuses,
    formatDate,
    formatSquarePrice,
    fulfillmentStatuses,
    homeSleepStudyStatuses,
    orderStatuses,
    orderTypes,
    priorAuthStatuses,
    stringPriceToNumber,
} from '../../../utils/constants';

import { useUpdatePatientProfileMutation } from '../../../app/services/auth';
import { useGetInsurancesQuery } from '../../../app/services/admin/insurance';
import { useUpdateOrderMutation } from '../../../app/services/admin/orders';
import { HorizontalLine } from '../../LayoutComponents';
import { toast } from 'react-toastify';

function GeneralOrderInformation({ order }) {
    //Local state
    const [values, setValues] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    //Redux Mutations
    const [updateOrder] = useUpdateOrderMutation();
    const [updatePatientProfile] = useUpdatePatientProfileMutation();

    //Insurance Query for changing insurance
    const { data: insurances, isFetching: insurancesFetching } = useGetInsurancesQuery(
        order.userId,
    );

    const { isHSTOrder } = determineOrderCategories(order);

    useEffect(() => {
        if (order)
            setValues({
                orderDate: order?.orderDate,
                discountCode: order?.discountCode,
                estimateStatus: order?.estimateStatus,
                fulfillmentStatus: order?.fulfillmentStatus,
                orderStatus: order?.orderStatus,
                orderType: order?.orderType,
                total: order?.total,
                totalDiscount: order?.totalDiscount,
                insuranceId: order?.insurance?.id ?? null,
            });
    }, [order]);

    //Track changed values and statuses to determine what to submit and whether reset button is disabled
    const changedValues = {};

    if (values) {
        Object.keys(values)
            .filter((key, i) => values[key] !== order[key])
            .forEach((k) => {
                changedValues[k] = values[k];
            });
    }
    // const { orderItemCategories } = determineOrderCategories(order);
    const itemTypes = determineItemCategoryTags(order);

    return insurancesFetching ? (
        <p>Loading..</p>
    ) : (
        <form
            method="post"
            onReset={() =>
                setValues({
                    orderDate: order?.orderDate,
                    discountCode: order?.discountCode,
                    estimateStatus: order?.estimateStatus,
                    fulfillmentStatus: order?.fulfillmentStatus,
                    orderStatus: order?.orderStatus,
                    orderType: order?.orderType,
                    total: order?.total,
                    totalDiscount: order?.totalDiscount,
                    insuranceId: order?.insurance?.id,
                })
            }
            onSubmit={async (e) => {
                e.preventDefault();
                setSubmitting(true);

                //To-Do: Determine if this needs to be replaced by another check that order is cancelled?
                //Determine if estimate is being rejected on a CPAP order
                //and CPAP order status is not set to NOT_INITIATED
                // if (
                //     changedValues.estimateStatus === 'ESTIMATE_REJECTED' &&
                //     orderItemCategories.includes('CPAP_SUPPLIES') &&
                //     order.user.patientProfile.cpapOrderStatus !== 'NOT_INITIATED' &&
                //     changedStatuses.cpapOrderStatus !== 'NOT_INITIATED'
                // ) {
                //     setSubmitting(false);
                //     return toast.error(
                //         'Cannot reject CPAP estimate without resetting CPAP order status',
                //         { autoClose: false },
                //     );
                // }

                if (Object.keys(changedValues).length > 0) {
                    const updatedOrder = await updateOrder({
                        orderId: order.id,
                        userId: order.userId,
                        values: {
                            ...changedValues,
                            discountCode: changedValues.discountCode?.toUpperCase(),
                            total: changedValues.total
                                ? stringPriceToNumber(changedValues.total)
                                : values.total,
                            totalDiscount: changedValues.totalDiscount
                                ? stringPriceToNumber(changedValues.totalDiscount)
                                : values.totalDiscount,
                            insuranceId: parseInt(values.insuranceId),
                        },
                    });

                    if (updatedOrder) {
                        toast.success('Order updated');
                    } else {
                        toast.error('Something went wrong');
                    }
                }

                setSubmitting(false);
            }}
            className="grid grid-cols-2 sm:grid-cols-[180px_1fr_180px_1fr] gap-x-4 gap-y-2 py-2 pl-2 items-center">
            <p>Order Id:</p>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">{order.id}</p>

            <p>Items in Order:</p>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                {order.orderItems?.length}
            </p>

            <p>Order Date:</p>
            <DatePicker
                className="appearance-none border border-gray rounded bg-transparent p-2 text-sm text-white"
                selected={values?.orderDate ? new Date(values?.orderDate) : null}
                onChange={(date) => {
                    setValues({
                        ...values,
                        orderDate: new Date(new Date(date).setUTCHours(17, 0, 0, 0)),
                    });
                }}
            />

            <p>Item Types:</p>
            <p>{itemTypes}</p>

            <p>Last Updated:</p>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                {formatDate(order?.updatedAt)}
            </p>

            <p>Discount Code:</p>
            <input
                className="text-black rounded-md pl-4 h-[37px]"
                type="text"
                placeholder="None"
                defaultValue={order.discountCode}
                name="discountCode"
                onChange={(e) =>
                    setValues({
                        ...values,
                        discountCode: e.target.value.length > 0 ? e.target.value : null,
                    })
                }
            />

            <p>Order Type:</p>
            <select
                className="w-full rounded-md text-black h-[37px]"
                multiple={false}
                defaultValue={order.orderType}
                name="orderType"
                onChange={(e) => setValues({ ...values, orderType: e.target.value })}>
                {orderTypes.map((orderType, index) => (
                    <option
                        className="text-white bg-[#2b2e3b]"
                        key={`orderType-${index}`}
                        value={orderType.value}>
                        {orderType.label}
                    </option>
                ))}
            </select>

            <p>Discount Amount:</p>
            <input
                className="text-black rounded-md pl-4 h-[37px]"
                type="text"
                defaultValue={formatSquarePrice(order.totalDiscount)}
                name="totalDiscount"
                onChange={(e) =>
                    setValues({
                        ...values,
                        totalDiscount: e.target.value,
                    })
                }
            />

            <p>Order Status:</p>
            <select
                className="w-full rounded-md text-black h-[37px]"
                multiple={false}
                defaultValue={order.orderStatus}
                name="orderStatus"
                onChange={(e) => setValues({ ...values, orderStatus: e.target.value })}>
                {orderStatuses.map((orderStatus, index) => (
                    <option
                        className="text-white bg-[#2b2e3b]"
                        key={`orderStatus-${index}`}
                        value={orderStatus.value}>
                        {orderStatus.label}
                    </option>
                ))}
            </select>

            <p className="col-start-3">Total Price:</p>
            <input
                className=" text-black rounded-md pl-4 h-[37px]"
                type="text"
                defaultValue={formatSquarePrice(order.total)}
                name="total"
                values={values?.total}
                onChange={(e) => setValues({ ...values, total: e.target.value })}
            />

            <p>Fulfillment Status:</p>
            <select
                className="w-full rounded-md text-black h-[37px]"
                multiple={false}
                defaultValue={order?.fulfillmentStatus}
                name="fulfullmentStatus"
                onChange={(e) => setValues({ ...values, fulfillmentStatus: e.target.value })}>
                {fulfillmentStatuses.map((opt, idx) => (
                    <option key={`fulfullmentStatus-${idx}`} value={opt}>
                        {opt}
                    </option>
                ))}
            </select>

            {isHSTOrder && (
                <>
                    <p>Sleep Study Status:</p>
                    <select
                        className="w-full rounded-md text-black h-full"
                        multiple={false}
                        defaultValue={order?.user?.patientProfile?.homeSleepStudyStatus}
                        name="homeSleepStudyStatus"
                        onChange={async (e) =>
                            await updatePatientProfile({
                                id: order.user.patientProfile.id,
                                userId: order.user.id,
                                orderId: order.id,
                                patientFields: {
                                    homeSleepStudyStatus: e.target.value,
                                },
                            })
                                .then(() => toast.success('Updated Status'))
                                .catch((error) => toast.error('Something went wrong'))
                        }>
                        {homeSleepStudyStatuses.map((opt, idx) => (
                            <option key={`homeSleepStudyStatus-${idx}`} value={opt}>
                                {opt}
                            </option>
                        ))}
                    </select>
                </>
            )}

            {order?.orderType === 'INSURANCE' && (
                <>
                    <HorizontalLine styling="col-span-4 my-1 text-gray-dark" />
                    <p>Insurance:</p>
                    <select
                        className="w-full rounded-md text-black h-[37px]"
                        multiple={false}
                        defaultValue={
                            order?.insurance && insurances?.length > 0
                                ? insurances.find(
                                      (insurance) => insurance?.id === order?.insurance?.id,
                                  ).id
                                : null
                        }
                        name="insuranceId"
                        onChange={(e) => setValues({ ...values, insuranceId: e.target.value })}>
                        <option className="text-gray" key="insurance-none" value={null}>
                            None
                        </option>
                        {insurances?.map((insurance, index) => (
                            <option
                                className="text-white bg-[#2b2e3b]"
                                key={`insurance-${index}`}
                                value={insurance.id}>
                                {insurance?.payer.name + ' (' + insurance?.insuranceStatus + ')'}
                            </option>
                        ))}
                    </select>

                    <p>Estimate Status:</p>
                    <select
                        className="w-full rounded-md text-black h-[37px]"
                        multiple={false}
                        defaultValue={order?.estimateStatus ? order?.estimateStatus : ''}
                        name="estimateStatus"
                        onChange={(e) => {
                            setValues({
                                ...values,
                                estimateStatus: e.target.value === '' ? null : e.target.value,
                            });
                        }}>
                        {estimateStatuses.map((estimateStatus, idx) => (
                            <option
                                key={`estimateStatus-${idx}`}
                                value={estimateStatus.value ? estimateStatus.value : ''}>
                                {estimateStatus.label}
                            </option>
                        ))}
                    </select>

                    <p className="col-start-3">Prior Auth Status:</p>
                    <select
                        className="w-full rounded-md text-black h-[37px]"
                        multiple={false}
                        defaultValue={order?.priorAuthStatus ? order?.priorAuthStatus : ''}
                        name="priorAuthStatus"
                        onChange={(e) => {
                            setValues({
                                ...values,
                                priorAuthStatus: e.target.value === '' ? null : e.target.value,
                            });
                        }}>
                        {priorAuthStatuses.map((priorAuthStatus, idx) => (
                            <option
                                key={`priorAuthStatus-${idx}`}
                                value={priorAuthStatus.value ? priorAuthStatus.value : ''}>
                                {priorAuthStatus.label}
                            </option>
                        ))}
                    </select>
                </>
            )}

            <button
                disabled={Object.keys(changedValues).length === 0 || submitting}
                type="submit"
                className="btn-primary-small col-start-1 col-span-2 lg:h-[42px]">
                Save
            </button>
            <button
                disabled={Object.keys(changedValues).length === 0 || submitting}
                type="reset"
                className="btn-secondary-small col-span-2 lg:h-[42px]">
                Reset
            </button>
        </form>
    );
}

export default GeneralOrderInformation;
