import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../app/services/auth';

const initialState = {
    cpapOrder: null,
    hstInCart: false,
    hstOrder: null,
    orderHistory: null,
    suppliesOrder: null,
};

export const ordersSlice = createSlice({
    name: 'catalog',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
                return initialState;
            })
            .addMatcher(api.endpoints.getOrderHistory.matchFulfilled, (state, { payload }) => {
                if (!payload) return;

                state.orderHistory = payload;
                state.hstOrder = payload.find(
                    (order) =>
                        order.orderStatus === 'COMPLETED' &&
                        order.orderItems.some((p) => p.itemCategory === 'SLEEP_STUDY'),
                );
                state.cpapOrder = payload.find(
                    (order) =>
                        order.orderStatus === 'COMPLETED' &&
                        order.orderItems.some((p) => p.itemCategory === 'CPAP_SUPPLIES'),
                );
                //Check for an order with supplies which is not a CPAP or SLEEP STUDY order
                state.suppliesOrder = payload.find((order) => {
                    return (
                        order.orderStatus === 'COMPLETED' &&
                        order.orderItems.some((p) => p.itemCategory === 'SUPPLIES') &&
                        order.orderItems.some(
                            (p) => !['SLEEP_STUDY', 'CPAP_SUPPLIES'].includes(p.itemCategory),
                        )
                    );
                });
            });
    },
});

export default ordersSlice.reducer;
