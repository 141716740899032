import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Modal, UploadFile } from '../..';
import {
    useChangeDocumentStatusMutation,
    useGetPatientFilesQuery,
    useUpdateDocumentNotesMutation,
} from '../../../app/services/admin/documents';
import {
    adminSelectCustomStyles,
    formatDate,
    modalSelectCustomStyles,
} from '../../../utils/constants';

//Images
import { ReactComponent as CheckCircle } from '../../../images/icons/check-circle.svg';
import { ReactComponent as Download } from '../../../images/icons/download.svg';
import { ReactComponent as SettingsIcon } from '../../../images/icons/settings.svg';
import { ReactComponent as Upload } from '../../../images/icons/upload.svg';

const DocumentsView = ({ patient }) => {
    const downloadBaseURL = process.env.REACT_APP_API_BASE_URL
        ? `${process.env.REACT_APP_API_BASE_URL}/files`
        : '/files';

    //Redux state values
    const { storageToken } = useSelector((state) => state.auth);

    //Queries and Mutations
    const { data: patientFiles, refetch } = useGetPatientFilesQuery(patient.id);
    const [
        changeDocumentStatus,
        { isSuccess: onDocumentStatusChangeSuccess, isError: onDocumentStatusChangeError },
    ] = useChangeDocumentStatusMutation();

    const [
        updateDocumentNotes,
        { isSuccess: onDocumentNotesUpdateSuccess, isError: onDocumentNotesUpdateError },
    ] = useUpdateDocumentNotesMutation();

    const [notesUpdateTimer, setNotesUpdateTimer] = useState(null);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadFileType, setUploadFileType] = useState('');
    const [fileTypeFilter, setFileTypeFilter] = useState('');

    const fileTypesMap = new Map([
        ['UPLOADED_SLEEP_STUDY', 'Uploaded Sleep Study'],
        ['UPLOADED_PRESCRIPTION', 'Uploaded Prescription'],
        ['UPLOADED_SLEEP_STUDY_WITH_PRESCRIPTION', 'Uploaded Sleep Study With Prescription'],
        ['ITAMAR_SLEEP_STUDY', 'Itamar Sleep Study'],
        ['REFERRAL', 'Written Order (Referral) Document'],
        ['PRESCRIPTION', 'Prescription'],
        ['INSURANCE_CARD_FRONT', 'Insurance Card Front'],
        ['INSURANCE_CARD_BACK', 'Insurance Card Back'],
        ['OTHER', 'Other Document'],
        ['CLINICAL_CHARTS', 'Clinical Charts'],
    ]);

    const fileTypeOptions = Array.from(fileTypesMap).map(([key, value]) => (
        <option key={value} value={key}>
            {value}
        </option>
    ));

    const fileTypeSelectOptions = Array.from(fileTypesMap).map(([key, value]) => ({
        label: value,
        value: key,
    }));

    const fileStatusOptions = [
        {
            label: 'In Review',
            value: 'IN_REVIEW',
        },
        {
            label: 'Approved',
            value: 'APPROVED',
        },
        {
            label: 'Rejected',
            value: 'REJECTED',
        },
    ];

    const handleDocumentStatusChange = async (fileId, status) => {
        await changeDocumentStatus({ fileId, status, patientId: patient.id });
    };

    const onNotesUpdate = (notes) => {
        clearTimeout(notesUpdateTimer);
        setNotesUpdateTimer(
            window.setTimeout(() => {
                updateDocumentNotes({
                    fileId: selectedFile.id,
                    notes,
                    patientId: patient.id,
                });
            }, 1500),
        );
    };

    useEffect(() => {
        if (onDocumentStatusChangeSuccess || onDocumentNotesUpdateSuccess) {
            toast.success('File updated successfully!');
        } else if (onDocumentStatusChangeError || onDocumentNotesUpdateError) {
            toast.error('File failed to update');
        }
    }, [
        onDocumentStatusChangeSuccess,
        onDocumentNotesUpdateSuccess,
        onDocumentNotesUpdateError,
        onDocumentStatusChangeError,
    ]);

    return (
        <div>
            <header className="mb-4 grid grid-cols-[1fr_320px_300px]">
                <h2 className="font-heading text-4xl">Patient Documents</h2>
                <Select
                    isClearable
                    isMulti
                    options={fileTypeSelectOptions}
                    className="w-[300px]"
                    onChange={(newValue) => {
                        if (newValue) {
                            setFileTypeFilter(newValue);
                        } else {
                            setFileTypeFilter('');
                        }
                    }}
                    placeholder={'Select File Type to Filter'}
                    styles={adminSelectCustomStyles}
                    value={fileTypeFilter}
                />
                <button
                    className="btn-primary-small flex justify-between w-[288px]"
                    onClick={() => {
                        setUploadFileType('');
                        setAddModalOpen(true);
                    }}>
                    Upload New Document <Upload />
                </button>
            </header>

            <div className="bg-[#ffffff0d] rounded p-8">
                {patientFiles?.length === 0 ? (
                    <p className="opacity-50">No files found.</p>
                ) : (
                    <ul>
                        {patientFiles?.length > 0 &&
                            patientFiles
                                .filter(
                                    (file) =>
                                        fileTypeFilter.length === 0 ||
                                        fileTypeFilter
                                            .map((filter) => filter.value)
                                            .includes(file.fileType),
                                )
                                .map((file, index) => (
                                    <li key={`${file.fileType}-${index}`} className="p-2">
                                        <div className="space-y-6 p-4 rounded border border-[#ffffff0d] [box-shadow:0_0_3px_rgba(0,_0,_0,_0.5)] bg-[#ffffff0d]">
                                            <div className="flex justify-between items-center">
                                                <p className="font-heading text-3xl">
                                                    <span className="text-gray mr-2">
                                                        {file.id}
                                                    </span>
                                                    {fileTypesMap.get(file.fileType)}
                                                </p>
                                                <div className="flex items-center space-x-4">
                                                    <a
                                                        className="border-white-500 inline-flex items-center rounded border bg-transparent py-1 px-3 text-base hover:bg-gray-darker"
                                                        href={`${downloadBaseURL}/${file.id}?storageToken=${storageToken}`}
                                                        download
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        Download
                                                        <Download className="ml-2" />
                                                    </a>
                                                    <button
                                                        className="transparent rounded text-base lg:text-lg"
                                                        onClick={() => {
                                                            setSelectedFile({ ...file });
                                                            setEditModalOpen(true);
                                                        }}>
                                                        <SettingsIcon className="hover:text-gray" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-[250px] md:grid-cols-[250px_250px] xl:grid-cols-[250px_250px_250px_100px] gap-x-2">
                                                <p className="flex">
                                                    <label className="w-[100px] font-bold">
                                                        Filename:
                                                    </label>
                                                    <div
                                                        className={`daisy-tooltip daisy-tooltip-right text-left flex items-center`}
                                                        data-tip={file.fileName}>
                                                        <span className="w-[160px] opacity-80 whitespace-nowrap overflow-hidden text-ellipsis">
                                                            {file.fileName}
                                                        </span>
                                                    </div>
                                                </p>
                                                <p className="flex">
                                                    <label className="w-[100px] font-bold">
                                                        Created At:
                                                    </label>
                                                    <span className="opacity-80">
                                                        {formatDate(file.createdAt)}
                                                    </span>
                                                </p>
                                                <p className="flex">
                                                    <label className="w-[100px] font-bold">
                                                        File Status:
                                                    </label>
                                                    <span className="opacity-80">
                                                        {file.status}
                                                    </span>
                                                    {file.status === 'APPROVED' && (
                                                        <CheckCircle className="text-gem-green ml-2" />
                                                    )}
                                                </p>
                                                <p className="flex">
                                                    <label className="w-[60px] font-bold">
                                                        Notes:
                                                    </label>
                                                    {file.notes && <CheckCircle />}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                    </ul>
                )}
            </div>
            <Modal
                showClose={true}
                isOpen={addModalOpen}
                closeHandler={() => setAddModalOpen(false)}>
                <div>
                    <h2 className="mb-4 text-3xl font-bold">Upload New Document</h2>
                    <select
                        value={uploadFileType}
                        onChange={(e) => {
                            setUploadFileType(e.target.value);
                        }}
                        className="w-full border-b border-b-gray-light bg-transparent hover:cursor-pointer">
                        <option value="">Please Select File Type</option>
                        {fileTypeOptions}
                    </select>
                    <div className="mt-6">
                        <UploadFile
                            isModal={true}
                            fileType={uploadFileType}
                            patientId={patient.id}
                            uploadSuccessCallback={(fileId) => {
                                // reset filetype
                                refetch();
                                window.setTimeout(() => {
                                    setAddModalOpen(false);
                                }, 2000);
                            }}
                        />
                    </div>
                </div>
            </Modal>

            <Modal
                showClose={true}
                isOpen={editModalOpen}
                closeHandler={() => setEditModalOpen(false)}>
                <h2 className="mb-4 text-3xl font-bold font-heading">
                    Edit Document: <span className="text-gray">{selectedFile?.fileName}</span>
                </h2>
                {selectedFile && (
                    <div className="max-w-lg space-y-4">
                        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
                            <label className="mb-2 sm:mb-0 font-bold min-w-[160px]">
                                Change File Status:
                            </label>
                            <Select
                                className="w-[300px]"
                                options={fileStatusOptions}
                                onChange={(option) => {
                                    handleDocumentStatusChange(selectedFile.id, option.value);
                                    setSelectedFile({
                                        ...selectedFile,
                                        status: option.value,
                                    });
                                }}
                                placeholder={'Select File Status'}
                                styles={modalSelectCustomStyles}
                                value={fileStatusOptions.find(
                                    (option) => option.value === selectedFile.status,
                                )}
                            />
                        </div>

                        <div className="flex flex-col">
                            <label className="mb-2 font-bold">Notes:</label>
                            <textarea
                                className="border rounded min-h-[300px]"
                                rows={8}
                                value={selectedFile.notes || ''}
                                onChange={(e) => {
                                    onNotesUpdate(e.target.value);
                                    setSelectedFile({ ...selectedFile, notes: e.target.value });
                                }}></textarea>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default DocumentsView;
