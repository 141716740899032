import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { ReactComponent as LeftArrow } from '../../images/left-arrow.svg';
import { toggle } from '../../features/ui/uiSlice';
import {
    formatDate,
    formatSquarePrice,
    getFormattedVariationOptionsBySku,
} from '../../utils/constants';

const AccountOrderHistory = () => {
    const dispatch = useDispatch();
    const { orderHistory } = useSelector((state) => state.orders);
    const { catalogOptions, catalogItems } = useSelector((state) => state.catalog);
    const { orderHistoryOpen } = useSelector((state) => state.ui);

    const ordersWithItems = orderHistory?.filter((order) => order.orderItems.length > 0);

    return (
        <motion.div
            key="account-order-history"
            className={`fixed right-0 z-40 h-screen w-screen overflow-y-auto bg-white px-5 py-16 text-gray-dark transition duration-300 lg:w-1/2 xl:w-1/3 lg:px-9 lg:py-32 ${
                orderHistoryOpen ? 'translate-x-0' : 'translate-x-full'
            }`}>
            <button
                className="absolute left-5 top-8 font-heading font-bold lg:left-9 lg:top-16"
                onClick={() => {
                    dispatch(toggle({ key: 'orderHistoryOpen', override: false }));
                }}>
                <LeftArrow className="text-black" />
            </button>
            <h5 className="mb-5 font-heading text-4xl font-bold lg:text-5xl">Order History</h5>

            <ul className="divide-y divide-gray-neutral border border-gray-neutral">
                {ordersWithItems?.map((order) => (
                    <li key={`${order.id}-${order.orderDate}`} className="relative p-5 pt-12">
                        <span className="absolute top-4 right-4 font-heading text-sm tracking-widest text-gray-six">
                            {formatDate(order.orderDate)}
                        </span>
                        <ul className="mb-7 space-y-2">
                            {order.orderItems?.map((orderItem) => (
                                <li
                                    key={`${orderItem.id}-${orderItem.orderId}`}
                                    className="flex space-x-4">
                                    <span className="text-sm font-bold">{orderItem.quantity}x</span>
                                    <div className="leading-tight">
                                        <p className="font-bold">
                                            <span className="mr-2">{orderItem.name}</span>
                                        </p>
                                        <ul className="text-sm">
                                            {getFormattedVariationOptionsBySku(
                                                orderItem.sku,
                                                catalogItems,
                                                catalogOptions,
                                            ).map((o, idx) => (
                                                <li key={`opt-${idx}`}>
                                                    {o.name}: {o.value}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        {order.orderType === 'INSURANCE' ? (
                            <>
                                <p className="text-sm font-bold">Cost: Pending Insurance</p>
                                <p>(if balance due, invoice will be sent)</p>
                            </>
                        ) : order.orderType === 'REPLACEMENT' ? (
                            <>
                                <p className="text-sm font-bold">Replacement</p>
                            </>
                        ) : (
                            <p className="flex items-center justify-between">
                                <span className="text-sm font-bold">
                                    ${formatSquarePrice(order.total)}
                                </span>
                            </p>
                        )}
                    </li>
                ))}
            </ul>
        </motion.div>
    );
};

export default AccountOrderHistory;
