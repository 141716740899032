import { motion } from 'framer-motion';
import { useInsuranceContext } from '../Insurance';
import { Link } from 'react-router-dom';

function InsuranceAlternativeCPAPOrderConfirmation() {
    const { insurance } = useInsuranceContext();

    if (!insurance) {
        return <p>Loading...</p>;
    } else
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="mx-auto max-w-2xl pt-16 text-center">
                <h2 className="mb-4 font-heading text-[44px] leading-[0.95] lg:text-7xl">
                    Thank you! Your CPAP Order is Being Processed
                </h2>

                {insurance?.payer?.name === 'The Care Navigation Benefit through Prime Dx' ? (
                    <>
                        <p className="mb-6 leading-normal lg:text-lg">
                            We've received your order and are working on it now!
                        </p>
                        <p className="mb-6 leading-normal lg:text-lg">
                            We will notify you via email with the next steps.
                        </p>
                    </>
                ) : (
                    <>
                        <p className="mb-6 leading-normal lg:text-lg">
                            We've received your order and are working on it now. Your CPAP should
                            ship in the next 3-5 business days. You will receive an email with
                            tracking information.
                        </p>
                    </>
                )}
                <Link to="/dashboard" className="btn-primary mb-6">
                    Return to home
                </Link>
            </motion.div>
        );
}

export default InsuranceAlternativeCPAPOrderConfirmation;
