import { api } from '../auth';
import {
    constructPaginatedOrderHistoryQuery,
    orderByObjectKeysForResupply,
} from '../../../utils/constants';

const adminOrdersAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        addOrderItem: builder.mutation({
            query: ({ orderId, userId, values }) => ({
                url: `/admin/user/${userId}/orders/${orderId}/items/`,
                method: 'POST',
                body: { ...values },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'OrderChanges', id: arg.orderId },
                { type: 'OrderHistory', id: arg.orderId },
                { type: 'PatientOrderHistory', id: arg.orderId },
                { type: 'OrderInfo', id: arg.orderId },
                'Cart',
            ],
        }),
        addShippingDetails: builder.mutation({
            query: ({ userId, orderId, values }) => ({
                url: `/admin/user/${userId}/orders/${orderId}/shipping`,
                method: 'POST',
                body: { ...values },
            }),
        }),
        createOrder: builder.mutation({
            query: ({ userId }) => ({
                url: `/admin/user/${userId}/orders`,
                method: 'POST',
            }),
            invalidatesTags: ['OrderHistory', 'PatientOrderHistory'],
        }),
        getPatientCart: builder.query({
            query: (userId) => `/purchases/cart?userId=${userId}`,
            providesTags: (result) => ['Cart'],
        }),
        getOrderInfo: builder.query({
            query: ({ userId, orderId }) => `/admin/user/${userId}/orders/${orderId}`,
            providesTags: (result) => [{ type: 'OrderInfo', id: result.id }],
        }),
        getPaginatedOrderHistory: builder.query({
            query: ({
                appointmentStatus,
                appointmentType,
                careTeam,
                coachingStatus,
                cpapOrderStatus,
                estimateStatus,
                fulfillmentStatus,
                homeSleepStudyStatus,
                mdStatus,
                initialCompliance,
                insurance,
                insuranceStatus,
                lastCompliance,
                startDate,
                endDate = null,
                orderDesc,
                orderByKey,
                orderItemType,
                orderStatus,
                orderType,
                priorAuthStatus,
                querySearch,
                referFromOrganization,
                skip,
                take = null,
                searchTermType,
                searchTermValue,
                supplyOrdersStatus,
            }) => {
                const formattedQuery = constructPaginatedOrderHistoryQuery({
                    appointmentStatus,
                    appointmentType,
                    careTeam,
                    coachingStatus,
                    cpapOrderStatus,
                    estimateStatus,
                    fulfillmentStatus,
                    homeSleepStudyStatus,
                    mdStatus,
                    initialCompliance,
                    insurance,
                    insuranceStatus,
                    lastCompliance,
                    startDate,
                    endDate,
                    orderDesc,
                    orderByKey,
                    orderItemType,
                    orderStatus,
                    orderType,
                    priorAuthStatus,
                    querySearch,
                    take,
                    referFromOrganization,
                    skip,
                    searchTermType,
                    searchTermValue,
                    supplyOrdersStatus,
                });

                return {
                    url: `/admin/orders`,
                    method: 'POST',
                    body: formattedQuery,
                };
            },
            providesTags: (result) =>
                result
                    ? [
                          ...result.results.map(({ id }) => ({ type: 'OrderHistory', id })),
                          'OrderHistory',
                      ]
                    : ['OrderHistory'],
        }),
        getPatientOrderHistory: builder.query({
            query: (userId) => `/admin/user/${userId}/orders`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'PatientOrderHistory', id })),
                          'PatientOrderHistory',
                      ]
                    : ['PatientOrderHistory'],
        }),
        getOrderChangesHistory: builder.query({
            query: (orderId) => `/history/orders/${orderId}`,
            providesTags: (result, error, arg) => [{ type: 'OrderChanges', id: arg }],
        }),
        getSquareOrders: builder.query({
            query: (userId) => `/admin/user/${userId}/squareorders/`,
            transformResponse: (response) => {
                return response.orders;
            },
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'PatientSquareOrderHistory', id })),
                          'PatientSquareOrderHistory',
                      ]
                    : ['PatientSquareOrderHistory'],
        }),
        removeOrderItem: builder.mutation({
            query: ({ orderId, userId, orderItemId }) => ({
                url: `/admin/user/${userId}/orders/${orderId}/items/${orderItemId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'OrderChanges', id: arg.orderId },
                { type: 'PatientOrderHistory', id: arg.orderId },
                { type: 'OrderInfo', id: arg.orderId },
                'Cart',
            ],
        }),
        removeShippingDetails: builder.mutation({
            query: ({ userId, orderId, shippingId }) => ({
                url: `/admin/user/${userId}/orders/${orderId}/shipping/${shippingId}`,
                method: 'DELETE',
            }),
        }),
        updateOrder: builder.mutation({
            query: ({ orderId, userId, values }) => ({
                url: `/admin/user/${userId}/orders/${orderId}`,
                method: 'PUT',
                body: { ...values },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'OrderChanges', id: arg.orderId },
                { type: 'OrderHistory', id: arg.orderId },
                { type: 'PatientOrderHistory', id: arg.orderId },
                { type: 'OrderInfo', id: arg.orderId },
            ],
        }),
        updateOrderItem: builder.mutation({
            query: ({ orderId, userId, orderItemId, values }) => ({
                url: `/admin/user/${userId}/orders/${orderId}/items/${orderItemId}`,
                method: 'PUT',
                body: { ...values },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'OrderChanges', id: arg.orderId },
                { type: 'OrderHistory', id: arg.orderId },
                { type: 'PatientOrderHistory', id: arg.orderId },
                { type: 'OrderInfo', id: arg.orderId },
                'Cart',
            ],
        }),
        updateResupply: builder.mutation({
            query: ({
                createdAt,
                enrollmentStatus,
                enrolledWithNiko,
                id,
                nextShipmentDate,
                userId,
            }) => ({
                url: `/users/${userId}/resupply/${id}`,
                method: 'PUT',
                body: {
                    createdAt: createdAt,
                    enrollmentStatus: enrollmentStatus,
                    enrolledWithNiko: enrolledWithNiko,
                    nextShipmentDate: nextShipmentDate,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: result.userId },
                'ResupplyList',
            ],
        }),
        getPaginatedResupply: builder.query({
            query: ({ whereObject, orderDesc, orderByKey, skip, userId }) => {
                const orderByObject = orderByObjectKeysForResupply.get(orderByKey);

                orderByObject[Object.keys(orderByObject)[0]] = orderDesc ? 'desc' : 'asc';

                return {
                    url: `/users/${userId}/resupply/search`,
                    method: 'POST',
                    body: {
                        skip: skip.toString(),
                        take: '10',
                        where: whereObject ? JSON.stringify({ ...whereObject }) : null,
                        orderBy: JSON.stringify(orderByObject),
                    },
                };
            },
            providesTags: (result) => ['ResupplyList'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useAddOrderItemMutation,
    useAddShippingDetailsMutation,
    useCreateOrderMutation,
    useGetOrderChangesHistoryQuery,
    useGetOrderInfoQuery,
    useGetPaginatedOrderHistoryQuery,
    useGetPatientOrderHistoryQuery,
    useGetPaginatedResupplyQuery,
    useGetPatientCartQuery,
    useGetSquareOrdersQuery,
    useUpdateOrderItemMutation,
    useUpdateOrderMutation,
    useRemoveOrderItemMutation,
    useRemoveShippingDetailsMutation,
    useUpdateResupplyMutation,
} = adminOrdersAPI;
